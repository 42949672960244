import React from "react"
import { Link } from "gatsby"
import postCardStyles from "../../styles/cards/blog-post-card.module.scss"
import Img from "gatsby-image"
// import EmbedVideo from "../components/common/embedVideo"

const BlogPostCard = ({ post }) => {
  const isDocumentValid = post.node.foto && post.node.titolo && post.node.slug && post.node.data
  if (isDocumentValid) {
  return (
    <div className={postCardStyles.cardBorderWrap}>
    <Link className={postCardStyles.card} to={`/blog/${post.node.slug}`}>
      <Img
        className={postCardStyles.image}
        fluid={post.node.foto.childImageSharp.fluid}
        alt={`foto natura - ${post.node.foto.name}`}
      />
      <span className={postCardStyles.title}>{post.node.titolo}</span>
      <span className={postCardStyles.signature}>dp - {post.node.data}</span>
    </Link></div>
  )} return null
}

export default BlogPostCard