import React from "react"
import Layout from "../components/common/layout"
import BlogPostCard from "../components/cards/blogPostCard"
import blogStyles from "../styles/pages/blog.module.scss"
import { graphql } from 'gatsby'

const BlogPage = ({ data, location }) => {
  return (
    <Layout
      pageMeta={{
        title: "Blog",
        keywords: ["massoterapia", "psicosomatica", "natura", "vita", "energia", "riflessologia plantare", "counseling"],
        description:
          "Donata si racconta attraverso approfondimenti su: Vita, Natura, Massoterapia, Riflessologia Plantare.",
        link: "/blog",
      }}
      location={location}
    >
      <h1 className="page-title">blog</h1>
      <ul className={blogStyles.blog}>
        {data.allStrapiPost.edges.map(post => (
          <li key={post.node.strapiId}>
            <BlogPostCard post={post} />
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default BlogPage

export const pageBlogQuery = graphql`
  query BlogPageQuery {
    allStrapiPost(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          strapiId
          titolo
          slug
          contenuto
          data(formatString: "DD MMMM, YYYY", locale: "it")
          foto {
            name
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`